
function soloNumeros(evt) {
  console.log('soloNumeros')
  var tecla = String.fromCharCode(evt.which || evt.keyCode);
  if (!/[-\d\b\r]/.test(tecla)) return false;
  return true;
}

function verificarNumerosChange(n /* idElement*/) {
  console.log('verificarNumerosChange')
  permitidos = /^-?([0-9]?)+/;
  if (!permitidos.test(n.value)) {
    n.value = "";
    n.focus();
    return false;
  }
  
  return true;
}

function setValuesSrPago(name, email, phone, street, city, zipcode, town,
  number, expiry_month, expiry_year, amount, token, idTransaction, months, isGift) {

  document.getElementById('name_span').innerHTML = name;
  document.getElementById('email_span').innerHTML = email;
  document.getElementById('phone_span').innerHTML = phone;
  document.getElementById('street_span').innerHTML = street;
  document.getElementById('city_span').innerHTML = city;
  document.getElementById('zipcode_span').innerHTML = zipcode;
  document.getElementById('town_span').innerHTML = town;
  document.getElementById('number_span').innerHTML = number;
  document.getElementById('expiry_month_span').innerHTML = expiry_month;
  document.getElementById('expiry_year_span').innerHTML = expiry_year;
  document.getElementById('amount_span').innerHTML = amount;
  document.getElementById('token_span').innerHTML = token;
  document.getElementById('idTransaction_span').innerHTML = idTransaction
  document.getElementById('months_span').innerHTML = months
  document.getElementById('isGift').innerHTML = isGift
  console.log("setValuesTest")
  document.getElementById('cvv').focus();

  document.getElementById('loadPromotions').click();

  return true;
}



function amexFormInitValues(sessionID, succesIndicator) {
  //document.getElementById('token_span').innerHTML = sessionID
  //7document.getElementById('idorder_span').innerHTML = idOrder
  //document.getElementById('idtransaction_span').innerHTML = idTransaction
  //document.getElementById('isGift').innerHTML = isGift
  document.getElementById('succesIndicator_span').innerHTML = succesIndicator

  //alert(sessionID)
  var jsonObject = {
    session: {
      id: sessionID
    },
    interaction: {
      merchant: {
        name: 'TEST8482158268'
      },
      operation: 'PURCHASE'
    },
    order:{
      description:'Payment'
    }
  }
  console.log(JSON.stringify(jsonObject))
  Checkout.configure(jsonObject);
  Checkout.showEmbeddedPage('#embed-target');
  //document.getElementById('detailTransactionBtn').click();
}

function errorCallback(error) {
  console.log("errorCallback")
  console.log(JSON.stringify(error));
  redirectPage('/error')
}
function cancelCallback() {
  console.log("cancelCallback")
  console.log('Payment cancelled');
}


function completeCallback(resultIndicator, sessionVersion) {
  console.log("completeCallback")
  const indicatorArray = resultIndicator.split(",");
  let indicatorResult = indicatorArray[0];
  let succesIndicator = document.getElementById('succesIndicator_span').innerHTML;

  if (succesIndicator == indicatorResult) {
    document.getElementById('detailTransactionBtn').click()

  }
  console.log(resultIndicator)
  console.log(sessionVersion)
}

function redirectPage(url) {
  console.log(url)
  location.href = url
}
function dataErrorMessage(error){
  console.log("dataErrorMessage")
  console.log(error)
}

